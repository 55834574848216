import {
   Box,
   Button,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
   Paper,
   Modal,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from '../../imports/MaterialImports'
import { useState, useCallback } from 'react'

import {
   getCurrentDate,
   calculateAge,
   calculateMetMen,
   calculateMetPorVo2,
   calculateMetWomen,
   calculateSlopeVeVco2,
   calculateWeber,
   calculateKcalMinConsumByVo2Max,
   calcaculeImc,
   calculateIrfc,
   calculateMyersScale,
   calculateOuesPredichoMen,
   calculatePercentageOuesPredichoMen,
   calculateOuesPredichoWomen,
   calculatePercentageOuesPredichoWomen,
   calculateChronotropicIndex,
   calculateVentilatoryPattern,
   calculateBasicAlgoritm,
   calculateOuesAlgoritmSubmaximumIcVsEpoc,
   calculateSuspectedEffortHtpX2,
   calculateSuspectedEffortPetCo2Vt1,
   calculateSuspectedEffortHtpSlopeVeVcO2,
   calculateFcMaxMen,
   calculateFcMaxWomen,
   calculateVt1Men,
   calculateVt2Men,
   calculateVt2Women,
   calculateVt1Women,
   calculateKarvonen,
   calculateKarvonen40,
   calculateKarvonen60,
   calculateKarvonen80,
   calculateIntensityPerErgospireTwoWeeks,
   calculateIntensityPerKarvonenTwoWeeks,
   calculateIntensityPerErgospireAllMonth,
   calculateIntensityPerKarvonenAllMonth,
   calculateIntensityPerErgospireSecondMonth,
   calculateIntensityPerKarvonenSecondMonth,
   calculaFcTheoryMax,
   calcaculePercentageFev1Fvc,
   calculateChronotropicReserve,
   calculateScorePlus,
   calculatePlusAlgoritm,
} from '../../services/common/CalculatorService'
import '../../styles/diagnosisStyle.css'
import { getCookieToken } from '../../services/cookies/CookieService'
import { getPdfReports } from '../../services/pdf/PdfService'
import { getPatientByUid, savePatient } from '../../services/patients/PatientService'
import { saveReport } from '../../services/reports/ReportService'
import { getExcel } from '../../services/excel/ExcelService'
import { DIAGNOSIS_LABEL } from '../../constants/Labels'
import { CircularProgress } from '@mui/material'

function createData(name, firstTwoWeeks, restOfTheFirstMonth, secondMonth) {
   return { name, firstTwoWeeks, restOfTheFirstMonth, secondMonth }
}

function DiagnosisPage() {
   const [diagnosis, setDiagnosis] = useState({
      nhc: '',
      name: '',
      secondName: '',
      birthdate: '',
      sex: '',
      age: '',
      reasonRequest: '',
      weight: '',
      size: '',
      takeBB: '',
      smoker: '',
      vo2Max: '',
      percentageVo2Max: '',
      percentageVo2MaxVt1: '',
      percentageVo2InVt1: '',
      percentageVo2InVt2: '',
      vo2Vt2: '',
      br: '',
      eqCo2Vt1: '',
      eqO2Max: '',
      slopeVeVco2: '',
      petCo2Repose: '',
      petCo2Vt1: '',
      petCo2Max: '',
      vdo2Vt2: '',
      basalFc: '',
      maxFc: '',
      fcTheory: '',
      minOneFc: '',
      percentageMaxO2Pulse: '',
      maxO2Pulse: '',
      o2PulseCurveMorphology: '',
      vdVt: '',
      percentageFevi1: '',
      fevi1L: '',
      fvc: '',
      feviFvc: '',
      vt: '',
      ouesReal: '',
      ecg: '',
      fcVt1: '',
      fcVt1Load: '',
      fcVt2: '',
      fcVt2Load: '',
      loadReached: '',
      rerPico: '',
      rer90Sec: '',
      duration: '',
      maxTas: '',
      fcVt1Lpm: 0,
      fcVt2Lpm: 0,
      sustainedArrhythmia: '',
      fcTheorymax: '',
      veMax: '',
      fRespiratoryMax: '',
      frMax: '',
      basalSatO2: '',
      picoSatO2: '',
      oxigenDebt: '',
      v02CalculateKcalMin: '',
      sportTime: '',
      chronotropicReserve: 0,
      madeIn: '',
      protocol: '',
      vo2: '',
      vo2Vt1: 0,
      reasonArrest: '',
      max: '',
      o2CurveMorphology: '',
      vtMorphology: '',
      oscillatingVentilation: '',
      percentageVo2Vt1Theory: '',
      percentageVo2MaxVt2: '',
      percentageVo2Vt2Theory: '',
      conclusion: `
Prueba maxima negativa. Sin arritmias. Perfil tensional de esfuerzo normal. Sin arritmias significativas. Buena capacidad funcional. 
      
Patron espirometrico basal normal sin agotamiento de la reserva ventilatoria
      
Capacidad funcional ligeramente reducida por VO2 y ligeramente reducida por OUES
      
Sin datos de ineficiencia respiratoria con VE/VCO2 slope normal y cinética normal de PETCO2 y EqCO2 en VT1 normal
      
Sin desaturación de esfuerzo
      `,
   })
   const [open, setOpen] = useState(false)

   const [loadingExcel, setLoadingExcel] = useState(false)
   const [loadingPdf, setLoadingPdf] = useState(false)
   const [loadingSave, setLoadingSave] = useState(false)

   const handleChangeDiagnosis = useCallback(
      event => {
         setDiagnosis({ ...diagnosis, [event.target.name]: event.target.value })
      },
      [diagnosis]
   )

   const handleSaveInform = () => {
      if (diagnosis.nhc === '') alert('Por favor, introduzca un NHC')

      if (diagnosis.nhc !== '') {
         setLoadingSave(true)

         setDiagnosis({ ...diagnosis, age: calculateAge(diagnosis) })

         const token = getCookieToken()

         savePatient(diagnosis, token)
            .then(({ data: newPatient }) => {
               getPatientByUid(newPatient.patientUID, token)
                  .then(({ data: patient }) => {
                     saveReport(patient, diagnosis, token)
                        .then(() => {
                           setLoadingSave(false)
                           alert('El informe se ha guardado correctamente')
                        })
                        .catch(error => {
                           setLoadingSave(false)
                           alert(`Se ha producido un error guardando el informe ${error?.message}`)
                        })
                  })
                  .catch(error => {
                     setLoadingSave(false)
                     alert(`Se ha producido un error buscando el paciente ${error?.message}`)
                  })
            })
            .catch(error => {
               setLoadingSave(false)
               alert(`Se ha producido un error guardando el paciente ${error?.message}`)
            })
      }
   }

   const handlePdf = () => {
      if (diagnosis.nhc === '') alert('Por favor, introduzca un NHC')

      if (diagnosis.nhc !== '') {
         setLoadingPdf(true)

         getPdfReports(getCookieToken(), diagnosis)
            .then(({ data }) => {
               setLoadingPdf(false)
               window.open(window.URL.createObjectURL(data))
            })
            .catch(error => {
               setLoadingPdf(false)
               alert(`Se ha producido un error al descargar el PDF ${error?.message}`)
            })
      }
   }

   const handleReset = () => {
      window.location.reload(false)
      window.scrollTo(0, 0)
   }

   const handleOpenModal = () => setOpen(true)

   const handleCloseModal = () => setOpen(false)

   const handleExcel = () => {
      setLoadingExcel(true)

      getExcel(getCookieToken())
         .then(response => response.blob())
         .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = blobUrl
            a.download = 'database-report.xls'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(blobUrl)

            setLoadingExcel(false)
         })
         .catch(error => {
            setLoadingExcel(false)
            alert(`Se ha producido un error al descargar el excel ${error?.message}`)
         })
   }

   const createTextField = (type, value, name, label, disabled) => {
      return (
         <TextField
            type={type}
            id={name}
            name={name}
            label={`${label}`}
            value={value}
            disabled={disabled}
            onChange={handleChangeDiagnosis}
            autoComplete="off"
            variant="filled"
            focused
            sx={{ m: 2 }}
            InputProps={{
               inputProps: type === 'number' ? { min: 0 } : {},
            }}
         />
      )
   }

   const createDropdown = (label, value, name, options) => (
      <FormControl sx={{ m: 2, minWidth: 250 }}>
         <InputLabel>{label}</InputLabel>
         <Select
            value={value}
            label={label}
            name={name}
            onChange={handleChangeDiagnosis}
            variant="filled"
            fullWidth
         >
            {options.map(({ id, item }) => (
               <MenuItem key={id} value={id}>
                  {item}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   )

   const cleanInformModal = () => (
      <Modal
         open={open}
         onClose={handleCloseModal}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Box
            sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 400,
               bgcolor: 'background.paper',
               border: '2px solid #000',
               boxShadow: 24,
               p: 4,
            }}
         >
            <Typography id="modal-modal-title" variant="h6" component="h2">
               ¿Quiere limpiar el infomre?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               Todos los datos se perderán...
            </Typography>
            <Button variant="contained" onClick={handleReset} style={{ margin: 22 }}>
               Aceptar
            </Button>
            <Button variant="contained" onClick={handleCloseModal} style={{ margin: 22 }}>
               Cancelar
            </Button>
         </Box>
      </Modal>
   )

   return (
      <Box>
         {cleanInformModal()}
         <Grid container justifyContent="center">
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers containers-align"
               id="container-demographic-data"
            >
               <legend>Datos Demográficos</legend>
               {createTextField(
                  'date',
                  getCurrentDate(),
                  'currentDate',
                  DIAGNOSIS_LABEL.currentDate
               )}
               {createTextField('text', diagnosis.nhc, 'nhc', DIAGNOSIS_LABEL.nhc)}
               {createTextField('text', diagnosis.name, 'name', 'Nombre')}
               {createTextField('text', diagnosis.secondName, 'secondName', 'Apellidos')}
               {createTextField('date', diagnosis.birthdate, 'birthdate', 'Fecha de nacimiento')}
               {createTextField('number', calculateAge(diagnosis), 'age', 'Edad', true)}
               {createDropdown('Sexo', diagnosis.sex, 'sex', [
                  { id: 'MALE', item: 'Hombre' },
                  { id: 'FEMALE', item: 'Mujer' },
               ])}
            </Grid>
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers"
               id="container-general-data-I-data"
            >
               <legend>Datos Generales (I)</legend>
               {createDropdown('Motivo petición', diagnosis.reasonRequest, 'reasonRequest', [
                  {
                     id: 'test diagnóstico de cardiopatía isquémica',
                     item: 'test diagnóstico de cardiopatía isquémica',
                  },
                  {
                     id: 'test pronóstico de cardiopatía isquémica',
                     item: 'test pronóstico de cardiopatía isquémica',
                  },
                  { id: 'insuficiencia cardiaca', item: 'insuficiencia cardiaca' },
                  { id: 'disnea', item: 'disnea' },
                  { id: 'otros', item: 'otros' },
               ])}

               {createTextField('number', diagnosis.weight, 'weight', 'Peso (kg)')}
               {createTextField('number', diagnosis.size, 'size', 'Talla (cm)')}
               {createTextField('number', calcaculeImc(diagnosis), 'imc', 'IMC', true)}
               {createDropdown('Toma BB', diagnosis.takeBB, 'takeBB', [
                  { id: '0', item: 'Sí' },
                  { id: '1', item: 'No' },
               ])}
               {createDropdown('Fumador', diagnosis.smoker, 'smoker', [
                  { id: '0', item: 'Sí' },
                  { id: '1', item: 'No' },
               ])}
            </Grid>
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers"
               id="container-initial-assessment-I"
            >
               <legend>Valoración Inicial (I)</legend>
               {createTextField('number', diagnosis.rerPico, 'rerPico', 'RER pico')}
               {createTextField('number', diagnosis.rer90Sec, 'rer90Sec', 'RER 90 (seg)')}
               {createTextField('number', diagnosis.duration, 'duration', 'Duración (min)')}
               {createDropdown('Realizado en', diagnosis.madeIn, 'madeIn', [
                  { id: 'Cinta', item: 'Cinta' },
                  { id: 'Bicicleta', item: 'Bicicleta' },
               ])}
               {createDropdown('Procolo', diagnosis.protocol, 'protocol', [
                  { id: 'Bruce rampa', item: 'Bruce rampa' },
                  { id: 'Bruce', item: 'Bruce' },
                  { id: 'Naughton', item: 'Naughton' },
                  { id: 'HTP HCS', item: 'Protocolo de HTP HCS' },
                  { id: 'OMS 25 W', item: 'Protocolo OMS 25 W' },
                  { id: 'OMS 50 W', item: 'Protocolo OMS 50 W' },
                  { id: 'OMS 75 W', item: 'Protocolo OMS 75 W' },
                  { id: 'Otro', item: 'Otro' },
               ])}
               {createTextField(
                  'number',
                  diagnosis.loadReached,
                  'loadReached',
                  'Carga alcanzada (W)'
               )}
               {createTextField('number', diagnosis.vo2, 'vo2', 'VO2/w (ml/kg/min)')}
               {createDropdown('Motivo detención', diagnosis.reasonArrest, 'reasonArrest', [
                  { id: 'disnea', item: 'disnea' },
                  { id: 'dolor torácico', item: 'dolor torácico' },
                  { id: 'síncope', item: 'síncope' },
                  { id: 'inadaptación', item: 'inadaptación' },
                  { id: 'test completo', item: 'test completo' },
               ])}
               {createDropdown('Máxima', diagnosis.max, 'max', [
                  { id: '0', item: 'Sí' },
                  { id: '1', item: 'No' },
               ])}
            </Grid>
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers"
               id="container-initial-assessment-II"
            >
               <legend>Valoración Inicial (II)</legend>
               {createTextField('number', diagnosis.vo2Max, 'vo2Max', 'VO2 max (ml/kg/min)')}
               {createDropdown(
                  'Morfología curva O2',
                  diagnosis.o2CurveMorphology,
                  'o2CurveMorphology',
                  [
                     { id: 'ascendente', item: 'ascendente' },
                     { id: 'descendente', item: 'descendente' },
                     { id: 'plano', item: 'plano' },
                  ]
               )}
               {createTextField(
                  'number',
                  diagnosis.percentageVo2InVt2,
                  'percentageVo2InVt2',
                  'VO2 max (%)'
               )}
               {createTextField(
                  'number',
                  diagnosis.percentageVo2InVt1,
                  'percentageVo2InVt1',
                  'VO2 en VT1 (%)'
               )}
               {createTextField('number', diagnosis.br, 'br', 'BR (%)')}
               {createTextField(
                  'number',
                  diagnosis.percentageMaxO2Pulse,
                  'percentageMaxO2Pulse',
                  'Pulso O2 (%)'
               )}
               {createTextField(
                  'number',
                  diagnosis.maxO2Pulse,
                  'maxO2Pulse',
                  'Pulso O2 pico (ml/latido)'
               )}
               {createDropdown(
                  'Morfología curva pulso O2',
                  diagnosis.o2PulseCurveMorphology,
                  'o2PulseCurveMorphology',
                  [
                     { id: 'ascendente', item: 'ascendente' },
                     { id: 'descendente', item: 'descendente' },
                     { id: 'plano', item: 'plano' },
                  ]
               )}
               {createTextField('number', diagnosis.ouesReal, 'ouesReal', 'OUES real')}
            </Grid>
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers"
               id="container-respiratory-efficiency"
            >
               <legend>Eficiencia Respiratoria</legend>
               {createTextField('number', diagnosis.eqCo2Vt1, 'eqCo2Vt1', 'Eq CO2 en VT1 (ml)')}
               {createTextField('number', diagnosis.slopeVeVco2, 'slopeVeVco2', 'Slope VE/VCo2')}
               {createTextField(
                  'number',
                  diagnosis.petCo2Repose,
                  'petCo2Repose',
                  'PET Co2 reposo (mmHq)'
               )}
               {createTextField('number', diagnosis.petCo2Vt1, 'petCo2Vt1', 'PET CO2 VT1 (mmHq)')}
               {createTextField('number', diagnosis.petCo2Max, 'petCo2Max', 'PET CO2 pico (mmHq)')}
               {createTextField('number', diagnosis.vdVt, 'vdVt', 'VD/VT pico (%)')}
               {createTextField('number', diagnosis.vdo2Vt2, 'vdo2Vt2', 'Eq O2 VT2 (ml)')}
               {createTextField('number', diagnosis.eqO2Max, 'eqO2Max', 'Eq O2 pico (ml)')}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-respiratory-data">
               <legend>Datos Respiratorios</legend>
               {createTextField(
                  'number',
                  diagnosis.percentageFevi1,
                  'percentageFevi1',
                  'FEVI1 (%)'
               )}
               {createTextField('number', diagnosis.fevi1L, 'fevi1L', 'FEV1 (l)')}
               {createTextField('number', diagnosis.fvc, 'fvc', 'FVC (%)')}
               {createTextField(
                  'number',
                  calcaculePercentageFev1Fvc(diagnosis),
                  'feviFvc',
                  'FEV1/FVC (%)',
                  true
               )}
               {createTextField('number', diagnosis.vt, 'vt', 'VT (l)')}
               {createDropdown('Morfología VT', diagnosis.vtMorphology, 'vtMorphology', [
                  { id: 'ascendente', item: 'ascendente' },
                  { id: 'descendente', item: 'descendente' },
                  { id: 'plano', item: 'plano' },
               ])}
               {createTextField('number', diagnosis.veMax, 'veMax', 'VE max (lpm)')}
               {createTextField(
                  'number',
                  diagnosis.fRespiratoryMax,
                  'fRespiratoryMax',
                  'F Resp max (rpm)'
               )}
               {createTextField('number', diagnosis.basalSatO2, 'basalSatO2', 'SatO2 basal (%)')}
               {createTextField('number', diagnosis.picoSatO2, 'picoSatO2', 'SatO2 pico (%)')}
               {createDropdown('Deuda de oxígeno', diagnosis.oxigenDebt, 'oxigenDebt', [
                  { id: '0', item: 'Sí' },
                  { id: '1', item: 'No' },
               ])}
               {createDropdown(
                  'Ventilación oscilante',
                  diagnosis.oscillatingVentilation,
                  'oscillatingVentilation',
                  [
                     { id: '0', item: 'Sí' },
                     { id: '1', item: 'No' },
                  ]
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-ergometric-data">
               <legend>Datos Ergométricos</legend>
               {createTextField('number', diagnosis.basalFc, 'basalFc', 'FC basal (lpm)')}
               {createTextField('number', diagnosis.maxFc, 'maxFc', 'FC máxima (lpm)')}
               {createTextField(
                  'number',
                  calculaFcTheoryMax(diagnosis),
                  'fcTheory',
                  'FC Teórica máxima (%)',
                  true
               )}
               {createTextField(
                  'number',
                  diagnosis.minOneFc,
                  'minOneFc',
                  'FC primer min recup (lpm)'
               )}
               {createTextField('number', diagnosis.maxTas, 'maxTas', 'TAS max')}
               {createTextField('number', diagnosis.fcVt1, 'fcVt1', 'FC VT1 (lpm)')}
               {createTextField('number', diagnosis.fcVt2, 'fcVt2', 'FC VT2 (lpm)')}
               {createDropdown('Interpretación ECG insquémica', diagnosis.ecg, 'ecg', [
                  { id: 'NO', item: 'No isquemia' },
                  { id: 'SI', item: 'Sí isquemia' },
                  { id: 'No variable', item: 'No varolable' },
               ])}
               {createDropdown(
                  'Arritmia sostenida',
                  diagnosis.sustainedArrhythmia,
                  'sustainedArrhythmia',
                  [
                     { id: '0', item: 'Sí' },
                     { id: '1', item: 'No' },
                  ]
               )}
            </Grid>
            <Grid
               item
               lg={6}
               md={6}
               xs={6}
               className="containers"
               id="container-data-in-thresholds"
            >
               <legend>Datos En Umbrales</legend>
               <Grid container spacing={1} columns={16}>
                  <Grid item m={1} xs={7}>
                     <Typography textAlign="center" fontWeight="regular">
                        VT1
                     </Typography>
                     {createTextField('number', diagnosis.fcVt1, 'fcVt1', 'FC VT1 (lpm)')}
                     {createTextField('number', diagnosis.fcVt1Load, 'fcVt1Load', 'Carga VT1 (W)')}
                     {createTextField(
                        'number',
                        diagnosis.percentageVo2Vt1Theory,
                        'percentageVo2Vt1Theory',
                        'VO2 VT1'
                     )}
                     {createTextField(
                        'number',
                        diagnosis.percentageVo2MaxVt1,
                        'percentageVo2MaxVt1',
                        'VO2 VT1 max (%)'
                     )}
                     {createTextField(
                        'number',
                        diagnosis.percentageVo2InVt1,
                        'percentageVo2InVt1',
                        'VO2 VT1 Teórico (%)'
                     )}
                  </Grid>
                  <Grid item m={1} xs={8}>
                     <Typography textAlign="center" fontWeight="regular">
                        VT2
                     </Typography>
                     {createTextField('number', diagnosis.fcVt2, 'fcVt2', 'FC VT2 (lpm)')}
                     {createTextField('number', diagnosis.fcVt2Load, 'fcVt2Load', 'Carga VT2 (W)')}
                     {createTextField('number', diagnosis.vo2Vt2, 'vo2Vt2', 'VO2 VT2')}
                     {createTextField(
                        'number',
                        diagnosis.percentageVo2MaxVt2,
                        'percentageVo2MaxVt2',
                        'VO2 VT2 max (%)'
                     )}
                     {createTextField(
                        'number',
                        diagnosis.percentageVo2Vt2Theory,
                        'percentageVo2Vt2Theory',
                        'VO2 VT2 teórico (%)'
                     )}
                  </Grid>
               </Grid>
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-risk-scale">
               <legend>Escala De Riesgos</legend>
               {createTextField(
                  'text',
                  calculateWeber(diagnosis),
                  'weberClass',
                  'Clase de Weber',
                  true
               )}
               {createTextField(
                  'text',
                  calculateSlopeVeVco2(diagnosis),
                  'ventilaroryClass',
                  'Clase de ventilación',
                  true
               )}
               {createTextField(
                  'text',
                  calculateMyersScale(diagnosis),
                  'myersScale',
                  'Escala de Myers',
                  true
               )}
               {createTextField(
                  'text',
                  calculateScorePlus(diagnosis),
                  'scorePlus',
                  'Score Plus',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-heart-rate">
               <legend>Frecuencia Cardiaca</legend>
               {createTextField('text', calculateIrfc(diagnosis), 'irfc', 'IRFC (lpm)', true)}
               {createTextField(
                  'text',
                  calculateChronotropicIndex(diagnosis),
                  'chronotropicIndex',
                  'Índice cronotrópico',
                  true
               )}
               {createTextField(
                  'number',
                  calculateChronotropicReserve(diagnosis),
                  'chronotropicReserve',
                  'Reserva cronotrópica (%)',
                  true
               )}
               {createTextField(
                  'text',
                  calculateVentilatoryPattern(diagnosis),
                  'ventilatoryPattern',
                  'Patrón ventilatorio',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-oues">
               <legend>OUES</legend>
               {createTextField(
                  'text',
                  calculateOuesPredichoMen(diagnosis),
                  'ouesPredichoMen',
                  'OUES predicho hombre',
                  true
               )}
               {createTextField(
                  'text',
                  calculatePercentageOuesPredichoMen(diagnosis),
                  'percentageOuesPredichoMen',
                  '% OUES predicho hombre',
                  true
               )}

               {createTextField(
                  'text',
                  calculateOuesPredichoWomen(diagnosis),
                  'ouesPredichoWomen',
                  'OUES predicho mujer',
                  true
               )}
               {createTextField(
                  'text',
                  calculatePercentageOuesPredichoWomen(diagnosis),
                  'percentageOuesPredichoWomen',
                  '% OUES predicho mujer',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-algorithm">
               <legend>Algoritmo</legend>
               {createTextField(
                  'text',
                  calculateBasicAlgoritm(diagnosis),
                  'basicAlgoritm',
                  'Algoritmo básico',
                  true
               )}
               {createTextField(
                  'text',
                  calculateOuesAlgoritmSubmaximumIcVsEpoc(diagnosis),
                  'ouesAlgoritm',
                  'Algoritmo OUES',
                  true
               )}
               {createTextField(
                  'text',
                  calculateSuspectedEffortHtpX2(diagnosis),
                  'suspectedEffortHtp',
                  'Sospecha HTP esfuerzo',
                  true
               )}
               {createTextField(
                  'text',
                  calculateSuspectedEffortPetCo2Vt1(diagnosis),
                  'suspectedEffortPetCo2Vt1',
                  'Sospecha HTP PETCo2',
                  true
               )}
               {createTextField(
                  'text',
                  calculateSuspectedEffortHtpSlopeVeVcO2(diagnosis),
                  'suspectedEffortHtpSlopeVeVcO2',
                  'Sospecha HTP slope VE/Vco2',
                  true
               )}
               {createTextField(
                  'text',
                  calculatePlusAlgoritm(diagnosis),
                  'plusAlgoritm',
                  'Algoritmo plus',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-fc-calculated">
               <legend>FC Calculado</legend>
               <Grid container spacing={1} columns={16}>
                  <Grid item m={1} xs={7}>
                     <Typography textAlign="center" fontWeight="regular">
                        Hombre
                     </Typography>
                     {createTextField(
                        'text',
                        calculateFcMaxMen(diagnosis),
                        'fcMaxMen',
                        'FC max hombre',
                        true
                     )}
                     {createTextField(
                        'text',
                        calculateVt1Men(diagnosis),
                        'vt1Men',
                        'FC VT1 hombre',
                        true
                     )}
                     {createTextField(
                        'text',
                        calculateVt2Men(diagnosis),
                        'calculateVt2Men',
                        'FC VT2 hombre',
                        true
                     )}
                  </Grid>
                  <Grid item m={1} xs={8}>
                     <Typography textAlign="center" fontWeight="regular">
                        Mujer
                     </Typography>
                     {createTextField(
                        'text',
                        calculateFcMaxWomen(diagnosis),
                        'fcMaxWomen',
                        'FC max mujer',
                        true
                     )}
                     {createTextField(
                        'text',
                        calculateVt1Women(diagnosis),
                        'calculateVt1Women',
                        'FC VT1 mujer',
                        true
                     )}
                     {createTextField(
                        'text',
                        calculateVt2Women(diagnosis),
                        'calculateVt2Women',
                        'FC VT2 mujer',
                        true
                     )}
                  </Grid>
               </Grid>
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-met-predicted">
               <legend>MET Predicho</legend>
               {createTextField(
                  'text',
                  calculateMetPorVo2(diagnosis),
                  'calculateMetPorVo2',
                  'MET POR VO2',
                  true
               )}
               {createTextField(
                  'text',
                  calculateMetWomen(diagnosis),
                  'calculateMetWomen',
                  'METS predichos mujer',
                  true
               )}
               {createTextField(
                  'text',
                  calculateMetMen(diagnosis),
                  'calculateMetMen',
                  'METS predichos hombre',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-kcal">
               <legend>Kcal</legend>
               {createTextField(
                  'text',
                  calculateKcalMinConsumByVo2Max(diagnosis),
                  'calculateKcalMinConsumByVo2Max',
                  'Kcal/min consum (en VO2 max)',
                  true
               )}
            </Grid>
            <Grid item lg={6} md={6} xs={6} className="containers" id="container-karvonen">
               <legend>Karvonen</legend>
               {createTextField(
                  'text',
                  calculateKarvonen(diagnosis),
                  'calculateKarvonen',
                  'KARVONEN',
                  true
               )}
               {createTextField(
                  'text',
                  calculateKarvonen40(diagnosis),
                  'calculateKarvonen40',
                  'KARVONEN 40',
                  true
               )}
               {createTextField(
                  'text',
                  calculateKarvonen60(diagnosis),
                  'calculateKarvonen60',
                  'KARVONEN 60',
                  true
               )}
               {createTextField(
                  'text',
                  calculateKarvonen80(diagnosis),
                  'calculateKarvonen80',
                  'KARVONEN 80',
                  true
               )}
            </Grid>
            <Grid item md xs={6} className="containers" id="container-table">
               <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Intensidad</TableCell>
                           <TableCell align="center">Primeras dos seamanas</TableCell>
                           <TableCell align="center">Resto primer mes</TableCell>
                           <TableCell align="center">Segundo mes</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {[
                           createData(
                              'Ergoespiro',
                              calculateIntensityPerErgospireTwoWeeks(diagnosis),
                              calculateIntensityPerErgospireAllMonth(diagnosis),
                              calculateIntensityPerErgospireSecondMonth(diagnosis)
                           ),
                           createData(
                              'Karvonen',
                              calculateIntensityPerKarvonenTwoWeeks(diagnosis),
                              calculateIntensityPerKarvonenAllMonth(diagnosis),
                              calculateIntensityPerKarvonenSecondMonth(diagnosis)
                           ),
                        ].map(row => (
                           <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component="th" scope="row">
                                 {row.name}
                              </TableCell>
                              <TableCell align="center">{row.firstTwoWeeks}</TableCell>
                              <TableCell align="center">{row.restOfTheFirstMonth}</TableCell>
                              <TableCell align="center">{row.secondMonth}</TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
            <Grid item md={12} xs={12} sx={{ m: 4 }} id="container-conclusions">
               <textarea
                  style={{ alignContent: 'left' }}
                  name="conclusion"
                  value={diagnosis.conclusion}
                  onChange={handleChangeDiagnosis}
                  placeholder="conclusiones"
                  rows={15}
                  cols={100}
               />
            </Grid>
         </Grid>

         {loadingSave ? (
            <CircularProgress style={{ margin: 22 }} />
         ) : (
            <Button variant="contained" onClick={handleSaveInform} style={{ margin: 22 }}>
               Guardar Informe
            </Button>
         )}
         {loadingPdf ? (
            <CircularProgress style={{ margin: 22 }} />
         ) : (
            <Button variant="contained" onClick={handlePdf} style={{ margin: 22 }}>
               Generar PDF
            </Button>
         )}
         {loadingExcel ? (
            <CircularProgress style={{ margin: 22 }} />
         ) : (
            <Button variant="contained" onClick={handleExcel} style={{ margin: 22 }}>
               Generar Excel
            </Button>
         )}

         <Button variant="contained" onClick={handleOpenModal} style={{ margin: 22 }}>
            Limpiar informe
         </Button>
      </Box>
   )
}

export default DiagnosisPage
