import { APP_NAME } from '../../constants/Labels'
import { Typography, Link } from '../../imports/MaterialImports'

function FooterComponent() {
   return (
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
         {'Copyright © '}
         <Link color="inherit" href="">
            {`${APP_NAME}`}
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

export default FooterComponent
