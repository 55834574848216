import { API_SAVE_REPORT } from '../../constants/ApiConstants'
import { doPost } from '../ApiService'
import { calcaculeImc, getCurrentDate } from '../common/CalculatorService'

const saveReport = (patient, diagnosis, token) =>
   doPost(
      API_SAVE_REPORT,
      {
         patientEntity: {
            patientID: patient.patientID,
            patientUID: patient.patientUID,
            name: patient.name,
            surname: patient.surname,
            birthdate: patient.birthdate,
            age: patient.age,
            sex: patient.sex,
         },
         reasonRequest: diagnosis.reasonRequest,
         weight: parseFloat(diagnosis.weight),
         size: parseFloat(diagnosis.size),
         imc: parseFloat(calcaculeImc(diagnosis)),
         takeBB: parseInt(diagnosis.takeBB),
         smoker: parseInt(diagnosis.smoker),
         rerPico: parseFloat(diagnosis.rerPico),
         rer90Sec: parseFloat(diagnosis.rer90Sec),
         duration: parseInt(diagnosis.duration),
         madeIn: diagnosis.madeIn,
         protocol: diagnosis.protocol,
         loadReached: parseFloat(diagnosis.loadReached),
         vo2: parseFloat(diagnosis.vo2),
         reasonArrest: diagnosis.reasonArrest,
         max: parseInt(diagnosis.max),
         vo2Max: parseFloat(diagnosis.vo2Max),
         o2CurveMorphology: diagnosis.o2CurveMorphology,
         percentageVo2InVt2: parseFloat(diagnosis.percentageVo2InVt2),
         percentageVo2InVt1: parseFloat(diagnosis.percentageVo2InVt1),
         br: parseFloat(diagnosis.br),
         percentageMaxO2Pulse: parseFloat(diagnosis.percentageMaxO2Pulse),
         maxO2Pulse: parseFloat(diagnosis.maxO2Pulse),
         o2PulseCurveMorphology: diagnosis.o2PulseCurveMorphology,
         ouesReal: parseFloat(diagnosis.ouesReal),
         eqCo2Vt1: parseFloat(diagnosis.eqCo2Vt1),
         slopeVeVco2: parseFloat(diagnosis.slopeVeVco2),
         petCo2Repose: parseFloat(diagnosis.petCo2Repose),
         petCo2Vt1: parseFloat(diagnosis.petCo2Vt1),
         petCo2Max: parseFloat(diagnosis.petCo2Max),
         vdVt: parseFloat(diagnosis.vdVt),
         vdo2Vt2: parseFloat(diagnosis.vdo2Vt2),
         eqO2Max: parseFloat(diagnosis.eqO2Max),
         percentageFevi1: parseFloat(diagnosis.percentageFevi1),
         fevi1L: parseFloat(diagnosis.fevi1L),
         fvc: parseFloat(diagnosis.fvc),
         feviFvc: parseFloat(0),
         vt: parseFloat(diagnosis.vt),
         vtMorphology: diagnosis.vtMorphology,
         veMax: diagnosis.veMax,
         fRespiratoryMax: parseFloat(diagnosis.fRespiratoryMax),
         basalSatO2: parseFloat(diagnosis.basalSatO2),
         picoSatO2: parseFloat(diagnosis.picoSatO2),
         oxigenDebt: parseInt(diagnosis.oxigenDebt),
         oscillatingVentilation: parseFloat(diagnosis.oscillatingVentilation),
         basalFc: parseFloat(diagnosis.basalFc),
         maxFc: parseFloat(diagnosis.maxFc),
         fcTheory: parseFloat(diagnosis.fcTheory),
         minOneFc: parseFloat(diagnosis.minOneFc),
         maxTas: parseFloat(diagnosis.maxTas),
         fcVt1: parseFloat(diagnosis.fcVt1),
         fcVt2: parseFloat(diagnosis.fcVt2),
         ecg: diagnosis.ecg,
         sustainedArrhythmia: parseInt(diagnosis.sustainedArrhythmia),
         fcVt1Load: parseFloat(diagnosis.fcVt1Load),
         percentageVo2MaxVt1: parseFloat(diagnosis.percentageVo2MaxVt1),
         percentageVo2Vt1Theory: parseFloat(diagnosis.percentageVo2Vt1Theory),
         fcVt2Load: parseFloat(diagnosis.fcVt2Load),
         vo2Vt2: parseFloat(diagnosis.vo2Vt2),
         percentageVo2MaxVt2: parseFloat(diagnosis.percentageVo2MaxVt2),
         percentageVo2Vt2Theory: parseFloat(diagnosis.percentageVo2Vt2Theory),
         conclusion: diagnosis.conclusion,
         createdAt: getCurrentDate(),
      },
      token
   )

export { saveReport }
