const _ENV = process.env

const API_BASE = _ENV.REACT_APP_API_BASE

const API_AUTH_ADDRESS = _ENV.REACT_APP_API_AUTH_ADDRESS
const API_EXIST_USER_BY_EMAIL = _ENV.REACT_APP_API_EXIST_USER_BY_EMAIL

const API_REPORTS_PDF = _ENV.REACT_APP_API_REPORTS_PDF

const API_SAVE_PATIENT = _ENV.REACT_APP_API_SAVE_PATIENT
const API_GET_PATIENT_BY_UID_PATIENT = _ENV.REACT_APP_API_GET_PATIENT_BY_UID

const API_SAVE_REPORT = _ENV.REACT_APP_API_SAVE_REPORT
const API_EXCEL = _ENV.REACT_APP_API_EXPORT_ALL_TO_EXCEL

export {
   API_BASE,
   API_AUTH_ADDRESS,
   API_EXIST_USER_BY_EMAIL,
   API_REPORTS_PDF,
   API_SAVE_PATIENT,
   API_GET_PATIENT_BY_UID_PATIENT,
   API_SAVE_REPORT,
   API_EXCEL,
}
