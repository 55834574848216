import { API_BASE, API_EXCEL } from '../../constants/ApiConstants'

const getExcel = auth =>
   fetch(`${API_BASE}${API_EXCEL}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
         Authorization: `Bearer ${auth}`,
      },
   })

export { getExcel }
