import { Route, Routes } from 'react-router-dom'

import DiagnosisPage from '../pages/diagnosis/DiagnosisPage'
import LoginPage from '../pages/login/LoginPage'

import { PATH_DIAGNOSIS, PATH_ROOT } from '../constants/Paths'
import { Navbar } from '../layouts/Layout'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter = () => (
   <Routes>
      <Route path={`${PATH_ROOT}`} element={<Navbar />}>
         <Route index element={<LoginPage />} />
         <Route
            path={`${PATH_DIAGNOSIS}`}
            element={
               <PrivateRoute>
                  <DiagnosisPage />
               </PrivateRoute>
            }
         />
      </Route>
   </Routes>
)
