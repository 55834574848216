import { createTheme } from '@mui/material'

export const layoutTheme = createTheme({
   palette: {
      primary: {
         main: '#cfccc3',
      },
      secondary: {
         main: '#fffff',
      },
   },
   typography: {
      fontFamily: ['-apple-system', 'Raleway, Arial'].join(','),
      fontSize: 16,
   },
   input: {
      focus: true,
   },
})
