import { API_SAVE_PATIENT, API_GET_PATIENT_BY_UID_PATIENT } from '../../constants/ApiConstants'
import { doPost, doGet } from '../ApiService'
import { calculateAge } from '../common/CalculatorService'

const savePatient = ({ nhc: patientUID, name, secondName: surname, birthdate, sex }, token) =>
   doPost(
      API_SAVE_PATIENT,
      {
         patientUID: patientUID === '' ? '' : parseInt(patientUID),
         name: name,
         surname: surname,
         birthdate: birthdate,
         sex: sex,
         age: calculateAge({ birthdate: birthdate }),
      },
      token
   )

const getPatientByUid = (patientUID, token) =>
   doGet(`${API_GET_PATIENT_BY_UID_PATIENT}${patientUID}`, token)

export { savePatient, getPatientByUid }
