import { API_BASE, API_REPORTS_PDF } from '../../constants/ApiConstants'
import axios from 'axios'

const getPdfReports = (auth, diagnosis) => {
   return axios.request({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_BASE}${API_REPORTS_PDF}?uid=${diagnosis.nhc}`,
      responseType: 'blob',
      headers: {
         Authorization: `Bearer ${auth}`,
      },
      data: diagnosis,
   })
}

export { getPdfReports }
