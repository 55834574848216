import { useNavigate } from 'react-router-dom'

import {
   Button,
   Avatar,
   CssBaseline,
   TextField,
   Link,
   Paper,
   Box,
   Grid,
   Typography,
} from '../../imports/MaterialImports'
import { FooterComponent } from '../../components/Components'
import { PATH_DIAGNOSIS } from '../../constants/Paths'
import { LOGIN_LABEL } from '../../constants/Labels'
import { login } from '../../services/auth/AuthService'
import { setCookieToken } from '../../services/cookies/CookieService'
import { useForm } from '../../hooks/useForm'
import { useState } from 'react'
import { CircularProgress } from '@mui/material'

function LoginPage() {
   const navigate = useNavigate()

   const { email, password, onInputChange, onResetForm } = useForm({
      email: '',
      password: '',
   })

   const [loading, setLoading] = useState(false)

   const handleSubmit = async event => {
            event.preventDefault()

      setLoading(true)

      login({ email: email, password: password })
         .then(({ data }) => {
            setCookieToken(data?.token)

            navigate(PATH_DIAGNOSIS, {
               replace: true,
               state: {
                  email: email,
                  logged: true,
               },
            })

            onResetForm()

            setLoading(false)
         })
         .catch(error => {
            setLoading(false)
            alert(`Verifica que los datos introduccidos son correctos ${error?.message}`)
         })
   }

   return (
      <Grid container component="main" sx={{ height: '100vh' }}>
         <CssBaseline />
         <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
               backgroundImage:
                  'url(https://res.cloudinary.com/dynri4yke/image/upload/v1704879250/logo-analisis-ergoespirometra.png)',
               backgroundRepeat: 'no-repeat',
               backgroundColor: theme =>
                  theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
               backgroundSize: 'cover',
               backgroundPosition: 'center',
            }}
         />
         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
               sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
               <Typography component="h1" variant="h5">
                  {`${LOGIN_LABEL.title}`}
               </Typography>
               <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                     margin="normal"
                     type="email"
                     name="email"
                     autoComplete="off"
                     variant="filled"
                     label={`${LOGIN_LABEL.email}`}
                     value={email}
                     onChange={onInputChange}
                     fullWidth
                     required
                  />

                  <TextField
                     margin="normal"
                     type="password"
                     name="password"
                     variant="filled"
                     autoComplete="on"
                     label={`${LOGIN_LABEL.password}`}
                     value={password}
                     onChange={onInputChange}
                     fullWidth
                     required
                  />

                  {loading ? (
                     <CircularProgress size={68} sx={{ mt: 3, mb: 2 }} />
                  ) : (
                     <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        {`${LOGIN_LABEL.start}`}
                     </Button>
                  )}
                  <FooterComponent />
               </Box>
            </Box>
         </Grid>
      </Grid>
   )
}

export default LoginPage
