import axios from 'axios'
import { API_BASE } from '../constants/ApiConstants'

const doGet = async (path, auth) => {
   return await axios.request({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_BASE}${path}`,
      headers: {
         Authorization: `Bearer ${auth}`,
      },
   })
}

const doPost = async (path, data, auth) =>
   await axios.request({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_BASE}${path}`,
      headers:
         typeof auth === 'string'
            ? {
                 'Content-Type': 'application/json',
                 Authorization: `Bearer ${auth}`,
              }
            : {
                 'Content-Type': 'application/json',
              },
      data: data,
   })

export { doGet, doPost }
