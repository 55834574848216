const formatDate = date => (date < 10 ? `0${date}` : date)

function getCurrentDate() {
   let date = new Date()

   return `${date.getFullYear()}-${formatDate(date.getMonth() + 1)}-${formatDate(date.getDate())}`
}

function calculateWeber({ vo2Max }) {
   //=SI(C12<10;"D";SI(C12<16;"C";SI(C12<21;"B";SI(C12>=AF328;"A";0))))

   if (vo2Max === '') return ''
   if (vo2Max < 10) return 'D'
   if (vo2Max < 16) return 'C'
   if (vo2Max < 21) return 'B'
   if (vo2Max >= 22) return 'A'
}

function calculateSlopeVeVco2({ slopeVeVco2 }) {
   //=SI(C17<=30;1;SI(C17<36;2;SI(C17<45;3;A17:AMJ17SI(C17>=45;4;error))))

   if (slopeVeVco2 === '') return ''
   if (slopeVeVco2 <= 30) return '1'
   if (slopeVeVco2 < 36) return '2'
   if (slopeVeVco2 < 45) return '3'
   if (slopeVeVco2 >= 45) return '4'
}

const calculateMetPorVo2 = ({ vo2Max }) =>
   vo2Max === '' ? '' : (parseFloat(vo2Max) / 3.5).toFixed(2)

const calculateAge = ({ birthdate }) =>
   birthdate === '' ? '' : new Date().getFullYear() - new Date(birthdate).getFullYear()

function calculateMetWomen({ birthdate }) {
   //=14,4 - (0,13*M4)
   let age = birthdate === '' ? '' : new Date().getFullYear() - new Date(birthdate).getFullYear()
   let met = age * 0.13

   return met === 0 ? '' : (14.4 - met).toFixed(2)
}

function calculateMetMen({ birthdate }) {
   //=18-(0,15*M4)
   let age = birthdate === '' ? '' : new Date().getFullYear() - new Date(birthdate).getFullYear()
   let met = age * 0.15

   return met === 0 ? '' : (18 - met).toFixed(2)
}

function calculateKcalMinConsumByVo2Max({ vo2Max, weight }) {
   //=C45*0,0175*C7
   let metPorVo2 = vo2Max === '' ? '' : parseFloat(vo2Max) / 3.5

   return metPorVo2 !== '' ? (metPorVo2 * 0.0175 * weight).toFixed(2) : ''
}

const calcaculeImc = ({ weight, size }) =>
   weight === '' && size === '' ? '' : (weight / ((size / 100) * (size / 100))).toFixed(2) //=C7/((C8/100)*(C8/100))

const calcaculeFEV1FVC = ({ percentageFevi1, fvc }) =>
   percentageFevi1 === '' && fvc === '' ? '' : ((percentageFevi1 / fvc) * 100).toFixed(2) //=(C26/C28)*100

const calculateMyersSlope = ({ slopeVeVco2 }) =>
   slopeVeVco2 === '' ? '' : slopeVeVco2 > 34 ? 7 : 0 //=SI(C17>34;7;0)

const calculateIrfc = ({ maxFc, minOneFc }) =>
   maxFc === '' || minOneFc === '' ? '' : maxFc - minOneFc //=C21-C22

const calculateMyersFc1Min = ({ maxFc, minOneFc }) =>
   maxFc === '' || minOneFc === '' ? '' : maxFc - minOneFc < 16 ? 5 : 0 //=C21-C22

const calculateMyersOues = ({ ouesReal }) => (ouesReal === '' ? '' : ouesReal < 1.4 ? 3 : 0)

const calculateMyersPETCO2 = ({ petCo2Repose }) =>
   petCo2Repose === '' ? '' : petCo2Repose < 33 ? 3 : 0 //=SI(C18<33;3;0)

const calculateMyersVO2MAX = ({ vo2Max }) => (vo2Max === '' ? '' : vo2Max < 14 ? 2 : 0) //=SI(C12<14;2;0)

const calculateMyersScale = diagnosis =>
   calculateMyersSlope(diagnosis) +
   calculateMyersFc1Min(diagnosis) +
   calculateMyersOues(diagnosis) +
   calculateMyersPETCO2(diagnosis) +
   calculateMyersVO2MAX(diagnosis) //=SUMA(M8;M9;M10;M11;M12;)

const calculateScoreVo2VT1 = ({ percentageVo2MaxVt1 }) =>
   percentageVo2MaxVt1 === '' ? '' : percentageVo2MaxVt1 < 40 ? 1 : 0 //=SI(C14<40;1;0)

const calculateScoreVo2Pico = ({ percentageVo2MaxVt1 }) =>
   percentageVo2MaxVt1 === '' ? '' : percentageVo2MaxVt1 < 80 ? 1 : 0 //=SI(C13<80;1;0)

const calculateScorePulseO2 = ({ maxO2Pulse }) => {
   if (maxO2Pulse === '') return ''
   if (maxO2Pulse !== '') return maxO2Pulse < 12 ? 1 : 0
} //=SI(C24<12;1;0)

const calculateScoreVentilatoryClass = ({ slopeVeVco2 }) => {
   //=SI(C17>35,9;1;0)
   if (slopeVeVco2 === '') return ''
   else return slopeVeVco2 > 35.9 ? 1 : 0
}

const calculateScoreOues = diagnosis => {
   if (calculatePercentageOuesPredichoMen(diagnosis) === '') return ''
   else return calculatePercentageOuesPredichoMen(diagnosis) < 80 ? 1 : 0
} //=SI(H14<80;1;0)

const calculateScorePlus = diagnosis =>
   calculateScoreVo2VT1(diagnosis) +
   calculateScoreVo2Pico(diagnosis) +
   calculateScorePulseO2(diagnosis) +
   calculateScoreVentilatoryClass(diagnosis) +
   calculateScoreOues(diagnosis) //=SUMA(M16;M15;M17;M18;M19)

function calculateOuesPredichoMen(diagnosis) {
   //=(909,7-11,51*M4+5,67*C8+8,62*C7-49,99*C10-214,53*C11+172,97*C27)/1000
   let age = calculateAge(diagnosis) //M4
   let size = diagnosis.size === '' ? '' : diagnosis.size //C8
   let weight = diagnosis.weight === '' ? '' : diagnosis.weight //C7
   let takeBB = diagnosis.takeBB === '' ? '' : diagnosis.takeBB //C10
   let smoker = diagnosis.smoker === '' ? '' : diagnosis.smoker //C11
   let fevi1L = diagnosis.fevi1L === '' ? '' : diagnosis.fevi1L //C27

   return diagnosis.sex === 'MALE'
      ? size !== '' && weight !== '' && takeBB !== '' && smoker !== '' && fevi1L !== ''
         ? (
              (909.7 -
                 11.51 * age +
                 5.67 * size +
                 8.62 * weight -
                 49.99 * takeBB -
                 214.53 * smoker +
                 172.97 * fevi1L) /
              1000
           ).toFixed(2)
         : ''
      : ''
}

function calculateOuesPredichoWomen(diagnosis) {
   //=(-182,4-8,89*M4+10,12*C8+10,51*C7-117,65*C10-21,45*C11+40,31*C27)/1000
   let age = calculateAge(diagnosis) //M4
   let size = diagnosis.size === '' ? '' : diagnosis.size //C8
   let weight = diagnosis.weight === '' ? '' : diagnosis.weight //C7
   let takeBB = diagnosis.takeBB === '' ? '' : diagnosis.takeBB //C10
   let smoker = diagnosis.smoker === '' ? '' : diagnosis.smoker //C11
   let fevi1L = diagnosis.fevi1L === '' ? '' : diagnosis.fevi1L //C27

   return diagnosis.sex === 'FEMALE'
      ? size !== '' && weight !== '' && takeBB !== '' && smoker !== '' && fevi1L !== ''
         ? (
              (-182.4 -
                 8.89 * age +
                 10.12 * size +
                 10.51 * weight -
                 117.65 * takeBB -
                 21.45 * smoker +
                 40.31 * fevi1L) /
              1000
           ).toFixed(2)
         : ''
      : ''
}

const calculatePercentageOuesPredichoMen = diagnosis =>
   diagnosis.sex === 'MALE'
      ? diagnosis.ouesReal === ''
         ? ''
         : ((diagnosis.ouesReal / calculateOuesPredichoMen(diagnosis)) * 100).toFixed(2)
      : '' //=(C29/M20)*100

const calculatePercentageOuesPredichoWomen = diagnosis =>
   diagnosis.sex === 'FEMALE'
      ? diagnosis.ouesReal === ''
         ? ''
         : ((diagnosis.ouesReal / calculateOuesPredichoWomen(diagnosis)) * 100).toFixed(2)
      : '' //=(C29/M21)*100

const calculateChronotropicIndex = diagnosis =>
   diagnosis.maxFc === '' || diagnosis.basalFc === ''
      ? ''
      : (
           (diagnosis.maxFc - diagnosis.basalFc) /
           (220 - calculateAge(diagnosis) - diagnosis.basalFc)
        ).toFixed(2) //=(C21-C20)/((220-M4)-C20)

const calculateChronotropicReserve = diagnosis =>
   diagnosis.maxFc === '' || diagnosis.basalFc === ''
      ? ''
      : (((diagnosis.maxFc - diagnosis.basalFc) / diagnosis.maxFc) * 100).toFixed(2) //=(C21-C20)/((220-M4)-C20)

function calculateVentilatoryPattern(diagnosis) {
   //=SI(M6<70;SI(C28<80;"MIXTO";"OBSTRUCTIVO");SI(C28<80;"RESTRICTIVO";"NORMAL"))
   let fev1Fvc = calcaculeFEV1FVC(diagnosis) //M6
   let fvc = diagnosis.fvc //C28
   if (fev1Fvc === '' || fvc === '') return ''
   if (fev1Fvc < 70) return fvc < 80 ? 'MIXTO' : 'OBSTRUCTIVO'
   else return fvc < 80 ? 'RESTRICTIVO' : 'NORMAL'
}

function calculateBasicAlgoritm(diagnosis) {
   //=SI(C13>=80;"Normal";SI(C14>40;SI(C15>20;"Desacondicionamiento fisico/isquemia/submaxima");SI(C14>40;"IC";"IC+EPOC")))

   //let percentageVo2Max = diagnosis.percentageVo2Max //C13
   let percentageVo2MaxVt1 = diagnosis.percentageVo2MaxVt1 //C14
   let br = diagnosis.br //C15

   if (percentageVo2MaxVt1 === '' || br === '') return ''
   if (percentageVo2MaxVt1 >= 80) return 'Normal'
   if (percentageVo2MaxVt1 > 40 && br > 20) return 'Desacondicionamiento fisico/isquemia/submaxima'
   if (percentageVo2MaxVt1 > 40) return 'IC'
   if (percentageVo2MaxVt1 <= 40) return 'IC+EPOC'
}

function calculateOuesAlgoritmSubmaximumIcVsEpoc(diagnosis) {
   //=SI(C13<=85;
   //SI(C15<33;"EPOC O EPOC/IC";
   //SI(H14<89;"IC";"OTRAS CAUSAS DE INTOLERANCIA AL ESFUERZO"));"OTRAS CAUSAS DE INTOLERANCIA AL ESFUERZO")
   let percentageVo2Max = diagnosis.percentageVo2MaxVt1 //C13
   let br = diagnosis.br //C15
   let percentageDelOuesPredichoMen = calculatePercentageOuesPredichoMen(diagnosis) //H14

   if (percentageVo2Max === '' && br === '') return ''
   if (percentageVo2Max <= 85 && br < 33) return 'EPOC O EPOC/IC'
   else if (percentageDelOuesPredichoMen < 89) return 'IC'
   else return 'OTRAS CAUSAS DE INTOLERANCIA AL ESFUERZO'
}

function calculatePlusAlgoritm(diagnosis) {
   //=SI(C13>=80;"Normal/ansiedad/Obesidad/Enfermedad pulmonar o cardiaca leve o en vidas de desarrollo";
   //SI(C14>=40;//SI(C15<30;"Enfermedad pulmonar";
   //SI(C30="NEGATIVO";"Esfuerzo pobre/Alteracion mmesqueletica";"cardiopatía isquémica"));
   //SI(C15<30;SI(C25>=25;"Enfermedad pulmonar con deterioro de la circulación pulmonar";"Acidosis metabólica crónica");
   //SI(C16<=32;"enfermedad arterial periférica/enfermedad cardiaca/anemia";"enfermedad vascular pulmonar o IC grave"))))
   let percentageVo2MaxVt1 = diagnosis.percentageVo2MaxVt1 //C13
   let br = diagnosis.br //C15
   let percentageVo2InVt1 = diagnosis.percentageVo2InVt1 //C14
   let ecg = diagnosis.ecg //C30 SI
   let vdVt = diagnosis.vdVt //C25
   let eqCo2Vt1 = diagnosis.eqCo2Vt1 //C16

   if (percentageVo2MaxVt1 > 80)
      return 'Normal/ansiedad/Obesidad/Enfermedad pulmonar o cardiaca leve o en vidas de desarrollo'
   else if (percentageVo2InVt1 >= 40 && br < 30) return 'Enfermedad pulmonar'
   else if (ecg === 'NO') return 'Esfuerzo pobre/Alteracion mmesqueletica'
   else if (ecg === 'SI' || ecg === 'No variable') return 'cardiopatía isquémica'
   else if (br < 30 && vdVt >= 25)
      return 'Enfermedad pulmonar con deterioro de la circulación pulmonar'
   else if (br > 30 && vdVt < 25) return 'Acidosis metabólica crónica'
   else if (eqCo2Vt1 !== '' && eqCo2Vt1 <= 32)
      return 'enfermedad arterial periférica/enfermedad cardiaca/anemia'
   else if (eqCo2Vt1 !== '' && eqCo2Vt1 > 32) return 'enfermedad vascular pulmonar o IC grave'
   else return ''
}

function calculateSuspectedEffortHtpX2(diagnosis) {
   //=SI(Y(C19>37; C17<30);"Poco probable";
   //SI(Y(C19>30;C19<36;C17>30; C17<38);"Considerarlo";
   //SI(Y(C19>20;C19<29;C17>39;C17<56);"Probable";
   //SI(Y(C19<20;C17>57);"Muy probable"))))

   let petCo2Vt1 = diagnosis.petCo2Vt1 //C19 12
   let slopeVeVco2 = diagnosis.slopeVeVco2 //C17 12

   if (petCo2Vt1 === '' && slopeVeVco2 === '') return ''

   if (petCo2Vt1 > 37 && slopeVeVco2 < 30) return 'Poco probable'
   else if (petCo2Vt1 > 30 && petCo2Vt1 < 36 && slopeVeVco2 > 30 && slopeVeVco2 < 38)
      return 'Considerarlo'
   else if (petCo2Vt1 > 20 && petCo2Vt1 < 29 && slopeVeVco2 > 39 && slopeVeVco2 < 56)
      return 'Probable'
   else if (petCo2Vt1 < 20 && slopeVeVco2 > 57) return 'Muy probable'
}

function calculateSuspectedEffortPetCo2Vt1(diagnosis) {
   //=SI(Y(C19>=37);"Poco probable";
   //SI(Y(C19>30;C19<36);"Considerarlo";
   //SI(Y(C19>20;C19<29);"Probable";
   //SI(Y(C19<=20);"Muy probable"))))

   let petCo2Vt1 = diagnosis.petCo2Vt1 //C19 32
   if (petCo2Vt1 === '') return ''

   if (petCo2Vt1 >= 37) return 'Poco probable'
   if (petCo2Vt1 > 30 && petCo2Vt1 < 36) return 'Considerarlo'
   if (petCo2Vt1 > 20 && petCo2Vt1 < 29) return 'Probable'
   if (petCo2Vt1 <= 20) return 'Muy probable'
}

function calculateSuspectedEffortHtpSlopeVeVcO2(diagnosis) {
   //=SI(Y(C17<=30);"Poco probable";
   //SI(Y(C17>30; C17<38);"Considerarlo";
   //SI(Y(C17>39;C17<56);"Probable";
   //SI(Y(C17>=57);"Muy probable"))))

   let slopeVeVco2 = diagnosis.slopeVeVco2 //C17 46
   if (slopeVeVco2 === '') return ''

   if (slopeVeVco2 <= 30) return 'Poco probable'
   if (slopeVeVco2 > 30 && slopeVeVco2 < 38) return 'Considerarlo'
   if (slopeVeVco2 > 39 && slopeVeVco2 < 56) return 'Probable'
   if (slopeVeVco2 >= 57) return 'Muy probable'
}

const calculateFcMaxMen = diagnosis =>
   diagnosis.sex === 'MALE'
      ? calculateAge(diagnosis) === ''
         ? ''
         : (208.7 - 0.73 * calculateAge(diagnosis)).toFixed(2)
      : ''
//=208,7-(0,73 *M4)

const calculateFcMaxWomen = diagnosis =>
   diagnosis.sex === 'FEMALE'
      ? calculateAge(diagnosis) === ''
         ? ''
         : (208.1 - 0.77 * calculateAge(diagnosis)).toFixed(2)
      : '' //=208,1-(0,77*M4)

const calculateVt1Men = diagnosis =>
   calculateFcMaxMen(diagnosis) !== '' ? (0.75 * calculateFcMaxMen(diagnosis)).toFixed(2) : '' //=0,75*M26

const calculateVt2Men = diagnosis =>
   calculateFcMaxMen(diagnosis) !== '' ? (0.85 * calculateFcMaxMen(diagnosis)).toFixed(2) : '' //=0,85*M26

const calculateVt1Women = diagnosis =>
   calculateFcMaxWomen(diagnosis) !== '' ? (0.75 * calculateFcMaxWomen(diagnosis)).toFixed(2) : '' //=0,75*M26

const calculateVt2Women = diagnosis =>
   calculateFcMaxWomen(diagnosis) !== '' ? (0.85 * calculateFcMaxWomen(diagnosis)).toFixed(2) : '' //=0,85*M26

const calculateKarvonen = ({ basalFc, maxFc }) =>
   basalFc === '' || maxFc === ''
      ? ''
      : (parseFloat(basalFc) + (parseFloat(maxFc) - parseFloat(basalFc))).toFixed(2) //=C20+(C21-C20)
const calculateKarvonen40 = ({ basalFc, maxFc }) =>
   basalFc === '' || maxFc === ''
      ? ''
      : (parseFloat(basalFc) + (parseFloat(maxFc) - parseFloat(basalFc)) * 0.4).toFixed(2) //=C20+((C21-C20)*0,4)
const calculateKarvonen60 = ({ basalFc, maxFc }) =>
   basalFc === '' || maxFc === ''
      ? ''
      : (parseFloat(basalFc) + (parseFloat(maxFc) - parseFloat(basalFc)) * 0.6).toFixed(2) //=C20+((C21-C20)*0,6)
const calculateKarvonen80 = ({ basalFc, maxFc }) =>
   basalFc === '' || maxFc === ''
      ? ''
      : (parseFloat(basalFc) + (parseFloat(maxFc) - parseFloat(basalFc)) * 0.8).toFixed(2) //=C20+((C21-C20)*0,8)

const calculateIntensityPerErgospireTwoWeeks = ({ basalFc, maxFc }) =>
   basalFc === '' || maxFc === ''
      ? ''
      : (parseFloat(basalFc) + (parseFloat(maxFc) - parseFloat(basalFc)) * 0.8).toFixed(2) //=C20+((C21-C20)*0,8)

const calculateIntensityPerKarvonenTwoWeeks = diagnosis => calculateKarvonen40(diagnosis) //=M36

const calculateIntensityPerErgospireAllMonth = ({ fcVt1 }) => (fcVt1 === '' ? '' : fcVt1 * 0.75) //=C31*0,75

const calculateIntensityPerKarvonenAllMonth = diagnosis => calculateKarvonen60(diagnosis) //=M37

const calculateIntensityPerErgospireSecondMonth = ({ fcVt1 }) =>
   fcVt1 === '' ? '' : (fcVt1 * 0.85).toFixed(2) //=C31*0,85

const calculateIntensityPerKarvonenSecondMonth = diagnosis => calculateKarvonen80(diagnosis) //=M38

const calculaFcTheoryMax = diagnosis =>
   calculateAge(diagnosis) === '' ? '' : 220 - calculateAge(diagnosis)

const calcaculePercentageFev1Fvc = ({ percentageFevi1, fvc }) =>
   percentageFevi1 === '' || fvc === '' ? '' : ((percentageFevi1 / fvc) * 100).toFixed(2)

export {
   getCurrentDate,
   calculateWeber,
   calculateSlopeVeVco2,
   calculateMetPorVo2,
   calculateAge,
   calculateMetWomen,
   calculateMetMen,
   calculateKcalMinConsumByVo2Max,
   calcaculeImc,
   calcaculeFEV1FVC,
   calculateIrfc,
   calculateMyersScale,
   calculateOuesPredichoMen,
   calculatePercentageOuesPredichoMen,
   calculateOuesPredichoWomen,
   calculatePercentageOuesPredichoWomen,
   calculateChronotropicIndex,
   calculateVentilatoryPattern,
   calculateBasicAlgoritm,
   calculateOuesAlgoritmSubmaximumIcVsEpoc,
   calculateSuspectedEffortHtpX2,
   calculateSuspectedEffortPetCo2Vt1,
   calculateSuspectedEffortHtpSlopeVeVcO2,
   calculateFcMaxMen,
   calculateFcMaxWomen,
   calculateVt1Men,
   calculateVt2Men,
   calculateVt1Women,
   calculateVt2Women,
   calculateKarvonen,
   calculateKarvonen40,
   calculateKarvonen60,
   calculateKarvonen80,
   calculateIntensityPerErgospireTwoWeeks,
   calculateIntensityPerKarvonenTwoWeeks,
   calculateIntensityPerErgospireAllMonth,
   calculateIntensityPerKarvonenAllMonth,
   calculateIntensityPerErgospireSecondMonth,
   calculateIntensityPerKarvonenSecondMonth,
   calculaFcTheoryMax,
   calculateChronotropicReserve,
   calcaculePercentageFev1Fvc,
   calculateScorePlus,
   calculatePlusAlgoritm,
}
