import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PATH_ROOT } from '../constants/Paths'
import { AppBar, Button, ThemeProvider, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { layoutTheme } from '../themes/Theme'
import { APP_NAME } from '../constants/Labels'

export const Navbar = () => {
   const { state } = useLocation()
   const navigate = useNavigate()

   const onLogout = () => {
      console.log(state)
      navigate(PATH_ROOT, {
         replace: true,
      })
   }

   return (
      <>
         <ThemeProvider theme={layoutTheme}>
            <Box>
               <AppBar position="static">
                  <Toolbar>
                     <Typography component="h6" sx={{ flexGrow: 1 }}>
                        {`${APP_NAME}`}
                     </Typography>
                     <Typography component="h6" sx={{ flexGrow: -1 }}>
                        {state?.email}
                     </Typography>
                     {state?.logged ? (
                        <Button variant="contained" onClick={onLogout} style={{ margin: 22 }}>
                           Cerrar sesión
                        </Button>
                     ) : (
                        <nav></nav>
                     )}
                  </Toolbar>
               </AppBar>
            </Box>
            <Outlet />
         </ThemeProvider>
      </>
   )
}
